// Import Variables
@import "variables";

// Spacing Helpers
@import "spacing";

// Utility Helpers
@import "utilities";

// HTML Elements
@import "elements/alerts";
@import "elements/buttons";
@import "elements/forms";
@import "elements/images";
@import "elements/navbar";
@import "elements/panels";
@import "elements/tables";
@import "elements/badges";

// Component Styling
@import "components/notifications";
@import "components/plans";
@import "components/settings";
@import "components/terms";

// Body Styling
body {
	font-weight: 400;
}

// Vue Cloak
[v-cloak] {
    display: none;
}
