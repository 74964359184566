.badge-primary, .badge-info, .badge-success, .badge-warning, .badge-danger {
  border: 0;
}
// Define variant badges
.badge-variant(@bgcolor; @textColor: #fff) {
    color: @textColor;
    background-color: @bgcolor;
    border-color: @bgcolor;
  }
 .badge-primary {
      .badge-variant(@brand-primary);
  }
  .badge-info {
      .badge-variant(@brand-info);
  }
  .badge-success {
      .badge-variant(@brand-success);
  }
  .badge-warning {
      .badge-variant(@brand-warning );
  }
  .badge-danger {
      .badge-variant(@brand-danger);
  }
